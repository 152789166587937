import React, {useEffect, useState} from 'react';
import styles from './event.module.css';
import {trackGoogleEvent, trackGooglePage} from "../../util/google-helpers";
import {EVENT_WID, GA_EVENTS} from "../../util/constants";
import useCMS from '@telescope/react-hooks/useCMS';

function Event(props) {
    const [text, setText] = useState(props.text)
    const [ widget ] = useCMS(EVENT_WID, {pollingFrequency: 5000, env: "uk" });

    useEffect(() => {
        trackGooglePage('Event Page');
    }, []);

    useEffect(() => {
      if ( widget && widget.text.event ) {
        setText(widget.text.event);
      }
  }, [widget]);

    return (
        <section className={styles.container}>
            <div className={styles.iframes_container}>
                <div className={ text.chat.active === "true" ? styles.video_container : styles.full_video_container }>
                    <iframe allowFullScreen={true} src={text.video_url} title="embed_video" className={styles.video}/>
                </div>
                {text.chat.active === "true" &&
                <div className={ styles.chat_container}>
                    <iframe src={text.chat.url} title="embed_chat" className={styles.chat}/>
                </div>}
            </div>
            <div className={styles.external_container}>
            <p dangerouslySetInnerHTML={{ __html: text.external_links.text }}></p>
              <a target="_blank" href={text.external_links.link_1.link}>
                {text.external_links.link_1.text}
              </a>
              <a target="_blank" href={text.external_links.link_2.link}>
                {text.external_links.link_2.text}
              </a>
            </div>
        </section>
    );
}

export default Event;
