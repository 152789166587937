import React, { useEffect } from 'react';
import { trackGooglePage } from '../../util/google-helpers';
import styles from './closed.module.css';

function Closed( props ) {

  const { copy } = props;

  useEffect(() => {
    trackGooglePage('Closed Page');
  }, []);

  return (
    <div className={ styles.closed }>
      <img className={styles.closed_image} src={copy.image} />
      <p className={ styles.paragraph } dangerouslySetInnerHTML={{ __html: copy.date }} />
      <p className={ styles.paragraph } dangerouslySetInnerHTML={{ __html: copy.time }} />
      <p className={ styles.paragraph } dangerouslySetInnerHTML={{ __html: copy.copy }} />
    </div>
  );
}

export default Closed;
