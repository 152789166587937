import React, { useEffect, useState } from 'react';
import useCMS from '@telescope/react-hooks/useCMS';
import api from './util/api';
import { COOKIES_ACCEPTANCE_NAME, COOKIES_LOGGED_USER, DEFAULT_WID, GA_EVENTS, TERMS_WID, VIEWS } from './util/constants';

import Closed from './components/closed';
import Landing from './components/landing';
import Event from './components/event';
import pym from 'pym.js';
import styles from './App.module.css';

import * as googleHelpers from './util/google-helpers';

import Div100vh from 'react-div-100vh';
import Nav from './components/nav';
import Agenda from './components/agenda';
import Terms from './components/terms';
import { createCookie, readCookie } from './util/helpers';
import CookiesBanner from './components/cookiesBanner';
import { trackGoogleEvent } from "./util/google-helpers";

new pym.Child({ polling: 500 });

function App() {
  const [userData, setUserData] = useState(false);
  const [showCookieBanner, setCookieBannerVisibility] = useState(true);
  const [eventData, setEventData] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [pollingFrequency, setPollingFrequency] = useState(5000);
  const [activePage, setActivePage] = useState(VIEWS.EVENT);

  const qsps = api.getQSPStorage();
  const [widget] = useCMS(qsps.wid || DEFAULT_WID, { sid: qsps.sid, pollingFrequency, env: "uk" });

  const appSettings = widget ? widget.settings : {};
  const windowStatus = widget ? parseInt(widget.settings.window_status, 10) : 0;

  if (appSettings.google_analytics && !googleHelpers.isInitialized()) {
    googleHelpers.initializeGoogleAnalytics(appSettings.google_analytics);
  }

  useEffect(() => {
    if (widget) {
      if (!isReady) setIsReady(true);
      setPollingFrequency(widget.settings.cms_poll_rate * 1000);
    }
  }, [widget, isReady]);

  useEffect(() => {
    if (userData) {
      api.fetchFundCms().then(response => {
        if (response && response.snapshot) {
          const data = response.snapshot.data;
          setEventData({
            data: data.data,
            text: data.text
          });
          setActivePage(VIEWS.EVENT);
        }
      });
    }
  }, [userData]);

  useEffect(() => {
    const cookiesAcceptance = readCookie(COOKIES_ACCEPTANCE_NAME);
    const userLogged = readCookie(COOKIES_LOGGED_USER);
    if (cookiesAcceptance && cookiesAcceptance === "1") {
      setCookieBannerVisibility(false);
    }

    if (userLogged && userLogged === "true") {
      setUserData(true);
    }
  }, []);

  const handleCookiesAcceptance = () => {
    createCookie(COOKIES_ACCEPTANCE_NAME, "1");
    setCookieBannerVisibility(false);
  };

  const navigateTo = (pageId) => {
    const { category, action } = GA_EVENTS.ROUTING_CLICK;
    trackGoogleEvent(category, action, pageId);
    setActivePage(pageId);
  };

  const {
    landing = {},
    closed = {},
    agenda = {}
  } = widget ? widget.text : {};

  let content;

  if (!isReady) {
    content = <div> Loading... </div>;
  } else if (!windowStatus && activePage !== VIEWS.AGENDA && activePage !== VIEWS.TERMS) {
    content = <Closed copy={closed} />;
  } else if (!userData) {
    content = <Landing copy={landing}
      appSettings={appSettings}
      setUserData={setUserData}
      navigator={setActivePage} />;
  } else if (eventData) {
    content = <Event text={eventData?.text.event} navigator={setActivePage} />;
  } else {
    content = "";
  }

  return (
    <Div100vh className={styles.app}>

      <header>
        <div className={styles.header_content}>
          <div className={styles.header_logos}>
            <div className={styles.header_left_logo}>
              {widget?.text.header.logos[0] && <img alt="" src={widget?.text.header.logos[0].image} />}
            </div>
            <div className={styles.header_right_logo}>
              {widget?.text.header.logos[0] && <img alt="" src={widget?.text.header.logos[1].image} />}
            </div>
          </div>
          <div className={styles.header_title}>
            <h1>{widget?.text.header.title}</h1>
          </div>
        </div>
      </header>

      <div className={styles.container}>
        {content}
      </div>

      <footer className={styles.footer_container}>
        <span>{widget?.text.footer.text}</span>
      </footer>

      {showCookieBanner && <CookiesBanner text={widget?.text.cookies_banner} callback={handleCookiesAcceptance} />}

    </Div100vh>
  );
}

export default App;
