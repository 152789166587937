// App Settings
export const DEFAULT_WID = '01b0d0e853725746';
export const EVENT_WID = '71b0f0d823028741';
export const TERMS_WID = '7180a0080268e6f8';
export const VERSION_CHECK = 'Jp0iKSQYj2lRWKOldg';
export const COOKIES_ACCEPTANCE_NAME = "cookies_acceptance";
export const COOKIES_LOGGED_USER = "cookies_login";

// Pages
export const VIEWS = {
  AGENDA: "agenda",
  EVENT: "event",
  CLOSED: "closed",
  LOGIN: "login",
  TERMS: "terms"
};

// Endpoints
export const API_CMS = 'https://widgetstate-uk.votenow.tv/v1/state/';
export const CONNECT_ENDPOINT = 'https://voteapi-uk.votenow.tv/s2/vote';

export const GA_EVENTS = {
  EMAIL_SUBMIT_SUCCESS: {
    category: 'click',
    action: 'button',
    label: 'email submit success'
  },
  EMAIL_SUBMIT_ERROR: {
    category: 'click',
    action: 'button',
    label: 'email submit error'
  },
  ROUTING_CLICK: {
    category: 'click',
    action: 'button',
  },
  FOOTER_CLICK: {
    category: 'click',
    action: 'link',
    label: 'footer link'
  }
};

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
